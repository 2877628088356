import { Ability, AbilityBuilder } from '@casl/ability';

const subjectName = (item) => {
  if (!item || typeof item === 'string') {
    return item;
  }
  return item.__type;
};

export const defineRulesFor = (auth) => {
  const { can, rules } = new AbilityBuilder();

  const roles = auth?.realmAccess?.roles || [];

  if (roles.some((value) => ['admin', 'admin_org'].includes(value))) {
    can('view', 'vozila');
    can('create', 'nalog');
    can('edit', 'nalog');
    can('view', 'filteri');
    can('view', 'admin');
    can('view', 'organization');
    can('create', 'vozila');
    can('create', 'servis');
    can('create', 'user');
    can('view', 'otkljucaj');
  }
  if (auth.role === 'guest') {
    can('view', 'vozila');
    can('view', 'filteri');
  }

  if (auth.role === 'manager') {
    can('view', 'vozila');
    can('view', 'filteri');
    can('view', 'admin');
    can('create', 'user');
  }

  if (auth.role === 'dispatcher') {
    can('view', 'vozila');
    can('create', 'nalog');
    can('edit', 'nalog');
    can('view', 'filteri');
    can('view', 'admin');
    can('create', 'vozila');
    can('create', 'servis');
  }

  if (roles.includes('driver')) {
    can('edit', 'kilometraza');
    can('edit', 'nalog');
  }

  return rules;
};

export const ability = new Ability([], { subjectName });
