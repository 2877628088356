import React, { useState, useEffect, useContext, createContext } from 'react';
import { ability, defineRulesFor } from '../casl/ability';

export const Context = createContext();

export const Provider = ({ children }) => {
  const [authUser, setAuthUser] = useState({ organization: '1000' });

  // Redundant user state used in TaskUpload in order to enable access to token
  const [firebaseUser, setFirebaseUser] = useState(null);
  const [redirectToReferrer, setRedirectToReferrer] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isAuthReady, setIsAuthReady] = useState(true);

  ability.update(defineRulesFor({ role: 'admin' }));

  const clearState = () => {
    setError(null);
    setRedirectToReferrer(false);
    setAuthUser(null);
  };

  const doSignInWithEmailAndPassword = async (email, password) => {
    try {
      setLoading(true);
      console.log('emaill---', email);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const doSignOut = async () => {
    clearState();
  };

  useEffect(() => {
    const unsubscribe = () => {
      setIsAuthReady(true);
      setRedirectToReferrer(true);
      setAuthUser({ organization: '1000' });
    };

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  // Make the context object:
  const authContext = {
    authUser,
    redirectToReferrer,
    loading,
    error,
    doSignInWithEmailAndPassword,
    doSignOut,
    setError,
    firebaseUser,
    setFirebaseUser,
    isAuthReady,
  };

  // pass the value in provider and return
  return <Context.Provider value={authContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

export const useAuthContext = () => useContext(Context);
