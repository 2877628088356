import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import ContextProvider from './context/Provider';
import ErrorBoundary from './components/ErrorBoundary';
import ScrollToTop from './components/ScrollToTop';
import Layout from './components/Layout';
import Routes from './routes';
import CacheBuster from 'react-cache-buster';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { version } from '../package.json';
import Keycloak from 'keycloak-js';
import 'antd/dist/antd.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
    },
  },
});



export const keycloakClient = new Keycloak({
  url:
    process.env.REACT_APP_KEYCLOAK_URL ||
    `https://keycloak.dev.infostudioclients.services`,
  realm: process.env.REACT_APP_KEYCLOAK_REALM || 'TMSdev',
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || 'smartscm',
  onLoad: 'login-required',
});

function App() {
  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={true} //If true, the library writes verbose logs to console.
      loadingComponent={<p>Provjera verzije.</p>} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={'.'} //If public assets are hosted somewhere other than root on your server.
    >
      <ReactKeycloakProvider
        authClient={keycloakClient}
        initOptions={{
          onLoad: 'login-required',
          checkLoginIframe: false,
        }}
      >
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <ContextProvider>
              <Router>
                <ScrollToTop />
                <Layout>
                  <Routes />
                </Layout>
              </Router>
            </ContextProvider>
          </QueryClientProvider>
        </ErrorBoundary>
      </ReactKeycloakProvider>
    </CacheBuster>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));
