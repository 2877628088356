import React, { createContext, useContext } from 'react';
import { createContextualCan } from '@casl/react';
import { ability, defineRulesFor } from '../casl/ability';
import { useKeycloak } from '@react-keycloak/web';

export const Context = createContext();

export const Provider = (props) => {
  const { keycloak } = useKeycloak();

  ability.update(defineRulesFor(keycloak));

  // Initial values are obtained from the props
  const { children } = props;

  // pass the value in provider and return
  return <Context.Provider value={ability}>{children}</Context.Provider>;
};

export const Can = createContextualCan(Context.Consumer);

export const useAbilityContext = () => useContext(Context);
